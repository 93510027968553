import { OwnUpNumberInput } from '@rategravity/own-up-component-library';
import React from 'react';

type NumberEditorProps = {
  name: string;
  optional: boolean;
  unit?: string;
  data: number | undefined;
  onDataUpdate: (data: number | undefined) => void;
};

const paramsForUnit = (
  unit?: string
): {
  prefix?: string;
  suffix?: string;
  formatValue?: (v?: number) => number | undefined;
  unformatValue?: (v: number) => number;
} => {
  switch (unit) {
    case undefined:
      return {};
    case 'money':
      return {
        prefix: '$'
      };
    case 'percent':
      return {
        suffix: '%',
        formatValue: (v) => (v !== undefined ? v * 100 : v),
        unformatValue: (v) => v / 100
      };
    case 'percentWhole':
      return {
        suffix: '%'
      };
    default:
      return {
        suffix: ` ${unit}`
      };
  }
};

/**
 * UI for editing number values, renders appropriate prefixes and suffixes and does appropriate additional formatting
 * based on the unit for the type.
 */
export const NumberEditor = ({ name, unit, optional, data, onDataUpdate }: NumberEditorProps) => {
  const { prefix, suffix, formatValue = (v) => v, unformatValue = (v) => v } = paramsForUnit(unit);
  return (
    <OwnUpNumberInput
      label={name}
      labelPosition="inner"
      value={formatValue(data) ?? ''}
      prefix={prefix}
      suffix={suffix}
      onChange={(event) => {
        const v = event.target.value.replaceAll(/[^0-9.-]/g, '');
        if (v === '' && optional) {
          onDataUpdate(undefined);
        } else {
          onDataUpdate(unformatValue(Number(v)));
        }
      }}
    />
  );
};
