import * as t from 'type-shift';

export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type DailyLimitByDay = {
  [key in Day as `dailyLimit${key}`]?: number;
};

export interface QueueCapacity {
  type: string;
  count: number;
}

export const queueCapacityConverter = t.strict<QueueCapacity>({
  type: t.string,
  count: t.number
});

export type LenderBudget = DailyLimitByDay & {
  lenderId: string;
  categorization: string[];
  monthlyBudget: number;
  monthlyGoal: number;
  budgetType: string;
  queueCapacity: QueueCapacity;
};

export const lenderBudgetConverter = t.strict<LenderBudget>({
  lenderId: t.string,
  monthlyBudget: t.number,
  monthlyGoal: t.number,
  categorization: t.array(t.string).default(() => []),
  dailyLimitMonday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitTuesday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitWednesday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitThursday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitFriday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitSaturday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  dailyLimitSunday: t.number.or(t.forPath([t.ParentPath, 'dailyLimit'], t.number)),
  budgetType: t.string,
  queueCapacity: queueCapacityConverter.default(() => ({ type: 'static', count: 0 }))
});
