import { USStates } from '@rategravity/core-lib/enums';
import { FactInfo } from './types';

export const facts: FactInfo[] = [
  {
    factId: 'creditRating',
    type: 'enumerated',
    values: ['Excellent', 'Good', 'Fair', 'Poor', 'Very Poor'],
    description: `The self-reported credit tier for the lead.
    
Credit tier is consistently defined for all lead sources, except Own Up which also supports a "Very Poor" tier.
Our partners do not provide credit tier granularity below a certain point so we cannot differentiate a "Poor" vs a "Very Poor" credit lead so we assume they are "Poor".

| Credit Score | Source | Tier      |
| ------------ | ------ | --------- |
| >=720        | Any    | Excellent |
| >=680        | Any    | Good      |
| >=620        | Any    | Fair      |
| >=580        | Own Up | Poor      |
| <580         | Own Up | Very Poor |
| <620         | Others | Poor      |
`
  },
  {
    factId: 'vaEligible',
    type: 'boolean',
    description: `Whether the lead is eligible for a VA loan. May be because they are a veteran or a surviving spouse.`
  },
  {
    factId: 'fthb',
    type: 'boolean',
    description: `Whether the lead is a first-time home buyer.`
  },
  {
    factId: 'propertyCity',
    type: 'string',
    description: `The city where the property is located.`
  },
  {
    factId: 'propertyState',
    type: 'enumerated',
    values: USStates,
    description: `The state where the property is located.`
  },
  {
    factId: 'propertyCounty',
    type: 'string',
    unit: 'county',
    description: `The county where the property is located.`
  },
  {
    factId: 'propertyZip',
    type: 'string',
    unit: 'zip',
    description: `The zip code where the property is located.`
  },
  {
    factId: 'propertyValue',
    type: 'number',
    unit: 'money',
    description: `The estimated value of the property.`
  },
  {
    factId: 'propertyType',
    type: 'enumerated',
    values: [
      'SingleFamily',
      'MultiFamily',
      'Condo',
      'Manufactured',
      'Townhome',
      'NewConstruction',
      'Mobile'
    ],
    description: `The type of property.`
  },
  {
    factId: 'propertyUse',
    type: 'enumerated',
    values: ['Primary', 'Secondary', 'Investment'],
    description: `The intended use of the property.`
  },
  {
    factId: 'loanPurpose',
    type: 'enumerated',
    values: ['Purchase', 'Refinance'],
    description: `The purpose of the loan.`
  },
  {
    factId: 'loanAmount',
    type: 'number',
    unit: 'money',
    description: `The amount of the loan.`
  },
  {
    factId: 'cashOutAmount',
    type: 'number',
    unit: 'money',
    description: `For a refinance scenario, how much money the shopper is looking to take out.`
  },
  {
    factId: 'currentMortgageBalance',
    type: 'number',
    unit: 'money',
    description: `The current balance on the shopper's mortgage.`
  },
  {
    factId: 'currentMortgageRate',
    type: 'number',
    unit: 'percentWhole',
    description: `When refinancing, the shopper's current mortgage rate.
For purchase deals, this is the most competitive rate they have been quoted.
(Stored as \`4.25\` for \`4.25%\`)`
  },
  {
    factId: 'jornayaLeadId',
    type: 'string',
    description: `The Jornaya lead ID associated with this lead.`
  },
  {
    factId: 'trustedFormCertificateUrl',
    type: 'string',
    description: `The TrustedForm certificate URL associated with this lead.`
  },

  {
    factId: 'source',
    type: 'enumerated',
    values: ['Own Up', 'rdc', 'mrc', 'bankrate', 'redfin', 'movoto'],
    description: `Where this lead was sourced from.`
  },
  {
    factId: 'sourceChannel',
    type: 'string',
    description: `The channel through which the lead was sourced.`
  },
  {
    factId: 'advisor',
    type: 'string',
    description: `The email of the advisor "featured" on this lead. If this was a referral questionnaire, this is the email of the advisor who referred the lead.`
  },
  {
    factId: 'leadPricePaid',
    type: 'number',
    unit: 'money',
    description: `The price paid for this lead (if provided.)`
  },
  {
    factId: 'leadType',
    type: 'enumerated',
    values: [
      'web-lead',
      'direct-web-lead',
      'preapproval-lead',
      'click-out-lead',
      'rate-quote-lead'
    ],
    description: `The type of lead. Dictates what ruleset is run.
 - \`web-lead\`: Leads received from one of our web forms, including the questionnaire and partner channels.
 - \`direct-web-lead\`: Leads manually being sold from Advisor Hub.
 - \`preapproval-lead\`: Preapproval Leads manually being sold from Advisor Hub.
 - \`rate-quote-lead\`: Leads quoted rates during a consult and manually being sold from Advisor Hub.
 - \`click-out-lead\`: Click-out Leads that can appear in various widgets.`
  },
  {
    factId: 'questionnaireType',
    type: 'enumerated',
    values: ['purchase', 'preapproval', 'refinance', 'accepted-offer'],
    description: `If the lead came from the Own Up questionnaire, what path they came from.`
  },
  {
    factId: 'bankruptcy',
    type: 'boolean',
    description: `Whether the lead has recently declared bankruptcy.`
  },
  {
    factId: 'dateLeadReceived',
    type: 'number',
    unit: 'timestamp',
    isDate: true,
    description: `The timestamp when the lead was received.`
  },
  /** Employment status */
  {
    factId: 'employmentStatus',
    type: 'enumerated',
    values: ['Employed', 'SelfEmployed', 'Retired', 'Unemployed'],
    description: `DEPRECATED - please use incomeType instead.
The employment status of the lead.`
  },
  {
    factId: 'exclusive',
    type: 'boolean',
    description: `DEPRECATED - do not use.
Never actually defined.`
  },
  {
    factId: 'hasCoborrower',
    type: 'boolean',
    description: `Whether the lead has a co-borrower.`
  },
  {
    factId: 'hasAgent',
    type: 'boolean',
    description: `Whether the lead is working with a real estate agent already.`
  },
  {
    factId: 'hasPreapproval',
    type: 'boolean',
    description: `Whether the lead has a preapproval.`
  },
  {
    factId: 'questionnaireStartTime',
    type: 'number',
    unit: 'timestamp',
    isDate: true,
    description: `The timestamp when the Own Up questionnaire was started.`
  },
  {
    factId: 'questionnaireCompletionTime',
    type: 'number',
    unit: 'timestamp',
    isDate: true,
    description: `The timestamp when the Own Up questionnaire was completed.`
  },
  {
    factId: 'purchaseTimeline',
    type: 'number',
    unit: 'months',
    description: `The number of months until the lead is looking to purchase a home.`
  },
  {
    factId: 'closeDate',
    type: 'number',
    unit: 'timestamp',
    isDate: true,
    description: `The date at which the lead is looking to close on their home.`
  },
  {
    factId: 'daysToClose',
    type: 'number',
    unit: 'days',
    description: `The number of days until the lead is looking to close on their home.`
  },
  {
    factId: 'grossIncome',
    type: 'number',
    unit: 'money',
    description: `The gross income of the lead.`
  },
  {
    factId: 'loanType',
    type: 'enumerated',
    values: ['Conventional', 'FHA', 'HELOC', 'Reverse', 'USDA', 'VA'],
    description: `The type of loan the lead is looking for.`
  },
  {
    factId: 'campaign',
    type: 'string',
    description: `The partner channel campaign associated with this lead.`
  },
  {
    factId: 'campaignId',
    type: 'string',
    description: `The partner channel campaign ID associated with this lead.`
  },
  {
    factId: 'hasAcceptedOffer',
    type: 'boolean',
    description: `Whether the lead has accepted an offer on a home already.`
  },
  {
    factId: 'oneUnitConformingLoanLimit',
    type: 'number',
    unit: 'money',
    description: `The conforming loan limit for a one-unit property in the lead's county.`
  },
  {
    factId: 'twoUnitConformingLoanLimit',
    type: 'number',
    unit: 'money',
    description: `The conforming loan limit for a two-unit property in the lead's county.`
  },
  {
    factId: 'threeUnitConformingLoanLimit',
    type: 'number',
    unit: 'money',
    description: `The conforming loan limit for a three-unit property in the lead's county.`
  },
  {
    factId: 'fourUnitConformingLoanLimit',
    type: 'number',
    unit: 'money',
    description: `The conforming loan limit for a four-unit property in the lead's county.`
  },
  {
    factId: 'hourOfDay',
    type: 'enumerated',
    values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    description: `The hour of the day the lead was received. (Eastern time, military format)`
  },
  {
    factId: 'dayOfTheWeek',
    type: 'enumerated',
    values: [0, 1, 2, 3, 4, 5, 6],
    description: `The current day of the week. (0 = Sunday, 1 = Monday, ...)`
  },
  {
    factId: 'currentDay',
    type: 'enumerated',
    values: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    description: `The current day of the week.`
  },
  {
    factId: 'currentMonth',
    type: 'enumerated',
    values: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    description: `The current month.`
  },
  {
    factId: 'currentYear',
    type: 'number',
    unit: 'year',
    description: `The current year.`
  },
  {
    factId: 'currentTimestamp',
    type: 'number',
    unit: 'timestamp',
    isDate: true,
    description: `The current timestamp.`
  },
  {
    factId: 'randomNumber',
    type: 'number',
    description: `A random number between 0 and 1. Note that this will be different for every instance of \`randomNumber\` used.`
  },
  {
    factId: 'downPaymentPct',
    type: 'number',
    unit: 'percent',
    description: `The down payment as a percentage of the property value in decimal format. (Stored as \`0.15\` for \`15%\`)`
  },
  {
    factId: 'ltv',
    type: 'number',
    unit: 'percent',
    description: `The loan-to-value ratio in decimal format. (Stored as \`0.85\` for \`85%\`)`
  },
  {
    factId: 'pricingTier',
    type: 'number',
    unit: 'money',
    params: {
      tier: {
        optional: false,
        type: 'enumerated',
        values: [
          // Level 1 Tiers
          'L1 PAL',
          'L1 AO',
          'L1 Click',
          'L1 Rate Only Refi',
          'L1 Cashout Refi',
          'L1 VA Purchase',
          'L1 Non-AO Purchase',
          'L1 Niche',
          // Level 2 Tiers
          'L2 PAL',
          'L2 AO',
          'L2 Click',
          'L2 Rate Only Refi',
          'L2 Cashout Refi',
          'L2 VA Purchase',
          'L2 Non-AO Purchase',
          'L2 Niche',
          // Level 3 Tiers
          'L3 PAL',
          'L3 AO',
          'L3 Click',
          'L3 Rate Only Refi',
          'L3 Cashout Refi',
          'L3 VA Purchase',
          'L3 Non-AO Purchase',
          'L3 Niche',
          // List Tiers
          'List PAL',
          'List AO',
          'List Click',
          'List Rate Only Refi',
          'List Cashout Refi',
          'List VA Purchase',
          'List Non-AO Purchase',
          'List Niche'
        ],
        description: `The pricing tier to retrieve.`
      }
    },
    description: `Global constants for pricing tiers.`
  },
  {
    factId: 'splitConfig',
    type: 'object',
    params: {
      splitName: {
        optional: false,
        type: 'string',
        description: `The name of the Split to check.`
      },
      splitKey: {
        optional: true,
        type: 'string',
        description: `The key that should be associated with this split. Defaults to \`leadID\`.`
      },
      defaultConfig: {
        optional: true,
        type: 'object',
        properties: {},
        description: `What to return if nothing is found.`
      }
    },
    properties: {},
    description: `Retrieve a Split configuration value.`
  },
  {
    factId: 'splitTreatment',
    type: 'string',
    params: {
      splitName: {
        optional: false,
        type: 'string',
        description: `The name of the Split to check.`
      },
      splitKey: {
        optional: true,
        type: 'string',
        description: `The key that should be associated with this split. Defaults to \`leadID\`.`
      }
    },
    description: `Retrieve a Split treatment value.`
  },
  {
    factId: 'isRocketDuplicate',
    type: 'boolean',
    description: `Whether the lead is a duplicate from Rocket Mortgage.`
  },
  {
    factId: 'utmSource',
    type: 'string',
    description: `The UTM source associated with this lead's most recent visit to the \`ownup.com\` domain within the last 24 hours.`
  },
  {
    factId: 'utmTerm',
    type: 'string',
    description: `The UTM term associated with this lead's most recent visit to the \`ownup.com\` domain within the last 24 hours.`
  },
  {
    factId: 'utmMedium',
    type: 'string',
    description: `The UTM medium associated with this lead's most recent visit to the \`ownup.com\` domain within the last 24 hours.`
  },
  {
    factId: 'utmContent',
    type: 'string',
    description: `The UTM content associated with this lead's most recent visit to the \`ownup.com\` domain within the last 24 hours.`
  },
  {
    factId: 'utmCampaign',
    type: 'string',
    description: `The UTM campaign associated with this lead's most recent visit to the \`ownup.com\` domain within the last 24 hours.`
  }
];
