import {
  IconLink,
  OwnUpCheckbox,
  OwnUpDropdown,
  OwnUpExtraSmallHeadlineBook,
  OwnUpFillButtonPrimary,
  OwnUpFillButtonSecondary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpMenuItem,
  OwnUpNumberInput,
  OwnUpSlimButtonPrimary,
  OwnUpSlimLineAccordion,
  OwnUpSmallHeadlineBook,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import { SAGE_100 } from '@rategravity/own-up-component-library/colors';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import { PlusIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/plus';
import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { AnyAction } from 'redux';
import styled from 'styled-components';
import { EDITOR_GROUPS } from '../../modules/authentication';
import { nonLenderIds } from '../../modules/constants';
import {
  updateLenderBudgetAction,
  updateLenderEnabledAction
} from '../../redux/lender-configuration/actions';
import {
  enabledLendersSelector,
  lenderBudgetsSelector
} from '../../redux/lender-configuration/selector';
import {
  DailyLimitByDay,
  Day,
  LenderBudget,
  QueueCapacity
} from '../../redux/lender-configuration/types';

export const Container = styled.div`
  margin: 24px 0;
  table {
    width: 100%;
    margin-top: 16px;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    text-align: center;
  }
`;

/**
 * Maps lender id to a map of budget type to the budget for that type
 */
type LenderBudgetsMap = Record<string, Record<string, LenderBudget>>;

interface BudgetsProps {
  editable: boolean;
  allLenderBudgets: LenderBudgetsMap;
  enabledLenders: string[];
  dispatch: Dispatch<AnyAction>;
}

type EditableBudget = LenderBudget & { edited?: boolean; allowEditType?: boolean };

const CheckBoxWrapper = styled.div`
  label {
    min-width: 0;
    padding: 12px 0 12px 12px;
  }
`;
const isQueueCapacityValid = (capacity: QueueCapacity) =>
  capacity.count <= 4 || capacity.type === 'static';

const queueCapacityTypes = [
  { value: 'static', label: 'Static (raw number of leads)' },
  { value: 'daysOfCapacityIncludeEmpty', label: 'Days of Capacity Include Empty' },
  { value: 'daysOfCapacityExcludeEmpty', label: 'Days of Capacity Exclude Empty' }
];

const BudgetRow = ({
  editing,
  editedBudget,
  allBudgetTypes
}: {
  editing: boolean;
  editedBudget: EditableBudget;
  allBudgetTypes: string[];
}) => {
  const [categorizationText, setCategorizationText] = useState('');
  const [error, setError] = useState(editedBudget.budgetType ? '' : 'Budget type cannot be blank');
  const [queueCapacityError, setQueueCapacityError] = useState('');
  useEffect(() => {
    // Ensure this error field is up to date whenever the queueCapacity changes
    setQueueCapacityError(
      isQueueCapacityValid(editedBudget.queueCapacity)
        ? ''
        : 'Queue capacity must be less than or equal to 4'
    );
  }, [editedBudget.queueCapacity]);

  const [, setEdited] = useState(editedBudget.edited);
  const [expanded, setExpanded] = useState(false);
  // Reset the categorization text when editing mode is toggled. This
  //   ensures that the text will be reset if the user cancels the edit
  useEffect(() => {
    setCategorizationText(editedBudget.categorization.join(', '));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);
  const onEdited = useCallback(() => {
    // Call this to make sure it renders
    setEdited(true);
    editedBudget.edited = true;
  }, [editedBudget]);

  return (
    <OwnUpSlimLineAccordion
      title={
        expanded || editing ? (
          ''
        ) : (
          <span style={{ opacity: '0.4' }}>
            <span>Budget Type: {editedBudget.budgetType}</span>
            <span style={{ marginLeft: '24px' }}>
              Monthly Budget: ${editedBudget.monthlyBudget.toLocaleString()}
            </span>
            <span style={{ marginLeft: '24px' }}>
              Categorization:{' '}
              {editedBudget.categorization.length > 0
                ? editedBudget.categorization.join(', ')
                : 'None'}
            </span>
          </span>
        )
      }
      expanded={expanded || editing}
      onChange={() => setExpanded(!expanded)}
      style={editedBudget.edited ? { backgroundColor: SAGE_100 } : {}}
    >
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem sm={12} lg={4}>
          <OwnUpTextInput
            label="Budget type"
            defaultValue={editedBudget.budgetType}
            // Only new budget types allow the budgetType to be edited
            disabled={!editedBudget.allowEditType}
            error={!!error}
            helperText={error}
            onChange={(event) => {
              editedBudget.budgetType = event.target.value;
              if (!editedBudget.budgetType) {
                setError('Budget type cannot be blank');
              } else if (allBudgetTypes.includes(editedBudget.budgetType)) {
                setError('Budget type must be unique');
              } else {
                setError('');
              }
            }}
          />
        </OwnUpGridItem>
        <OwnUpGridItem sm={12} lg={8}>
          <OwnUpTextInput
            value={categorizationText}
            label="Categorization"
            onChange={(event) => {
              onEdited();
              setCategorizationText(event.target.value);
              editedBudget.categorization = event.target.value.split(',').map((x) => x.trim());
            }}
            disabled={!editing}
          />
        </OwnUpGridItem>
        <OwnUpGridItem sm={12} lg={6}>
          <OwnUpNumberInput
            value={editedBudget.monthlyBudget}
            onValueChange={(value: NumberFormatValues) => {
              onEdited();
              return (editedBudget.monthlyBudget = value.floatValue!);
            }}
            decimalScale={0}
            thousandSeparator
            labelPosition="inner"
            label="Monthly Budget"
            disabled={!editing}
            $leadingIcon={<DollarIcon />}
            allowNegative={false}
          />
        </OwnUpGridItem>
        <OwnUpGridItem sm={12} lg={6}>
          <OwnUpNumberInput
            value={editedBudget.monthlyGoal}
            onValueChange={(value: NumberFormatValues) => {
              onEdited();
              return (editedBudget.monthlyGoal = value.floatValue!);
            }}
            decimalScale={0}
            thousandSeparator
            labelPosition="inner"
            label="Monthly Goal"
            disabled={!editing}
            $leadingIcon={<DollarIcon />}
            allowNegative={false}
          />
        </OwnUpGridItem>
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
          (day, index) => (
            <OwnUpGridItem sm={12} lg={4} key={index}>
              <OwnUpNumberInput
                value={editedBudget[`dailyLimit${day as Day}`]}
                onValueChange={(value: NumberFormatValues) => {
                  onEdited();
                  return (editedBudget[`dailyLimit${day as Day}`] = value.floatValue!);
                }}
                label={`${day.substring(0, 3)} Limit`}
                decimalScale={0}
                thousandSeparator
                labelPosition="inner"
                disabled={!editing}
                allowNegative={false}
              />
            </OwnUpGridItem>
          )
        )}
        <OwnUpGridItem sm={12} lg={8}>
          <OwnUpDropdown
            value={editedBudget.queueCapacity.type}
            label="Queue Capacity Type"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              onEdited();
              editedBudget.queueCapacity = {
                ...editedBudget.queueCapacity,
                type: event.target.value as string
              };
            }}
            disabled={!editing}
            style={{ textAlign: 'left' }}
          >
            {queueCapacityTypes.map(({ value, label }, index) => (
              <OwnUpMenuItem key={index} value={value}>
                {label}
              </OwnUpMenuItem>
            ))}
          </OwnUpDropdown>
        </OwnUpGridItem>
        <OwnUpGridItem sm={12} lg={4}>
          <OwnUpNumberInput
            value={editedBudget.queueCapacity.count}
            onChange={(event) => {
              onEdited();
              editedBudget.queueCapacity = {
                ...editedBudget.queueCapacity,
                count: Number(event.target.value)
              };
              if (!isQueueCapacityValid(editedBudget.queueCapacity)) {
                setQueueCapacityError('Queue capacity must be less than or equal to 4');
              } else {
                setQueueCapacityError('');
              }
            }}
            decimalScale={0}
            labelPosition="inner"
            label="Queue Capacity"
            disabled={!editing}
            allowNegative={false}
            helperText={queueCapacityError}
            error={!!queueCapacityError}
          />
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpSlimLineAccordion>
  );
};

const ButtonsContainer = styled.div`
  margin: 0 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

/**
 * Creates a deep copy of the budgets
 */
const deepCopyBudgets = (budgets: Record<string, LenderBudget>) =>
  Object.values(budgets).reduce(
    (acc, val) => ({
      ...acc,
      [val.budgetType]: { ...val, edited: undefined }
    }),
    {} as Record<string, LenderBudget>
  );

const LenderRow = ({
  editable,
  lenderBudgets,
  lenderId,
  lenderEnabled,
  dispatch
}: {
  editable: boolean;
  lenderBudgets: Record<string, LenderBudget>;
  lenderId: string;
  lenderEnabled: boolean;
  dispatch: Dispatch<AnyAction>;
}) => {
  const [editing, setEditing] = useState(false);
  // editedBudgets is a copy of the budgets, so canceling can reset to original values without an api call
  const [editedBudgets, setEditedBudgets] = useState<Record<string, EditableBudget>>(
    deepCopyBudgets(lenderBudgets)
  );
  const [editedLenderEnabled, setEditedLenderEnabled] = useState(lenderEnabled);

  // Creates a new budget with the budgetType editable
  const handleNewBudgetTypeClick = useCallback(() => {
    setEditedBudgets({
      ...editedBudgets,
      // assuming this `newBudgetFlag` won't conflict with any budget types
      newBudgetFlag: {
        lenderId,
        ...Object.keys(Day).reduce(
          (acc, day) => ({ ...acc, [`dailyLimit${day as Day}`]: 0 }),
          {} as DailyLimitByDay
        ),
        categorization: [],
        monthlyBudget: 0,
        monthlyGoal: 0,
        budgetType: '',
        edited: true,
        allowEditType: true,
        queueCapacity: { type: 'static', count: 0 }
      }
    });
  }, [editedBudgets, lenderId]);

  const handleSubmitClick = useCallback(() => {
    if (editing) {
      Object.values(editedBudgets).map((editedBudget) => {
        if (editedBudget.edited) {
          if (isQueueCapacityValid(editedBudget.queueCapacity)) {
            // Only update budgets that have been edited if they are valid
            dispatch(updateLenderBudgetAction(editedBudget));
          } else {
            // Don't update the budget if the queue capacity is invalid. Reset budgets to original values
            setEditedBudgets(deepCopyBudgets(lenderBudgets));
          }
        }
      });
      if (editedLenderEnabled != lenderEnabled) {
        dispatch(updateLenderEnabledAction(lenderId, editedLenderEnabled));
      }
    }
    setEditing(!editing);
  }, [
    editing,
    editedLenderEnabled,
    lenderEnabled,
    dispatch,
    lenderId,
    editedBudgets,
    lenderBudgets
  ]);

  const handleCancel = useCallback(() => {
    // Reverts to a copy of the original budgets
    setEditedBudgets(deepCopyBudgets(lenderBudgets));
    setEditedLenderEnabled(lenderEnabled);
    setEditing(false);
  }, [lenderEnabled, lenderBudgets]);

  const budgetRows = useMemo(() => {
    return Object.values(editedBudgets).map((editedBudget, index) => {
      return (
        <BudgetRow
          editing={editing}
          editedBudget={editedBudget}
          allBudgetTypes={Object.keys(editedBudgets)}
          key={index}
        />
      );
    });
  }, [editedBudgets, editing]);

  const match = useRouteMatch();

  return (
    <tr>
      <td>
        <Link to={`${match.path}/${lenderId}`}>{lenderId}</Link>
      </td>
      <td>
        <div>{budgetRows}</div>
        <div style={{ display: 'flex', visibility: editing ? undefined : 'hidden' }}>
          <OwnUpSlimButtonPrimary
            icon={PlusIcon}
            disabled={!editing}
            onClick={handleNewBudgetTypeClick}
          >
            New Budget Type
          </OwnUpSlimButtonPrimary>
        </div>
      </td>
      <td>
        <CheckBoxWrapper>
          <OwnUpCheckbox
            checked={editedLenderEnabled}
            disabled={!editing}
            onChange={() => setEditedLenderEnabled(!editedLenderEnabled)}
          />
        </CheckBoxWrapper>
      </td>
      {editable && (
        <td>
          <ButtonsContainer>
            <OwnUpFillButtonPrimary onClick={handleSubmitClick}>
              {editing ? 'Submit' : 'Edit'}
            </OwnUpFillButtonPrimary>
            <OwnUpFillButtonSecondary
              onClick={handleCancel}
              style={{ visibility: editing ? 'visible' : 'hidden' }}
            >
              Cancel
            </OwnUpFillButtonSecondary>
          </ButtonsContainer>
        </td>
      )}
    </tr>
  );
};

type DailyTotalByDay = {
  [key in Day as `dailyTotal${key}`]: number;
};

type BudgetTotal = DailyTotalByDay & {
  monthlyTotal: number;
};

/**
 * Sums all budgets by type for the list of lenderIds
 */
const sumLenderBudgets = (lenderIds: string[], lenderBudgets: LenderBudgetsMap) => {
  // Loop through the listed lenders, excluding anywhere and ownUp
  return lenderIds.reduce(
    (budgetTotals, lenderId) => {
      if (!nonLenderIds.includes(lenderId)) {
        // Loop through all the budget types for each lender
        if (lenderBudgets[lenderId]) {
          Object.entries(lenderBudgets[lenderId]).map(([budgetType, budget]) => {
            if (!budgetTotals[budgetType]) {
              // Intialize sum to 0 if this budget type is new
              budgetTotals[budgetType] = {
                ...Object.keys(Day).reduce(
                  (acc, day) => ({ ...acc, [`dailyTotal${day as Day}`]: 0 }),
                  {} as DailyTotalByDay
                ),
                monthlyTotal: 0
              };
            }
            // Sum up the budgets by type and day
            Object.keys(Day).map(
              (day) =>
                (budgetTotals[budgetType][`dailyTotal${day as Day}`] +=
                  budget[`dailyLimit${day as Day}`] || 0)
            );
            budgetTotals[budgetType].monthlyTotal += budget.monthlyBudget;
          });
        }
      }
      return budgetTotals;
    },
    {} as Record<string, BudgetTotal>
  );
};

export const BudgetsImpl = ({
  editable,
  allLenderBudgets,
  enabledLenders,
  dispatch
}: BudgetsProps) => {
  const [hideDisabledLenders, setHideDisabledLenders] = useState(true);
  const disabledLenders = useMemo(
    () => Object.keys(allLenderBudgets).filter((id) => !enabledLenders.includes(id)),
    [allLenderBudgets, enabledLenders]
  );

  const enabledLenderBudgets = useMemo(
    () => sumLenderBudgets(enabledLenders, allLenderBudgets),
    [allLenderBudgets, enabledLenders]
  );
  const disabledLenderBudgets = useMemo(
    () => sumLenderBudgets(disabledLenders, allLenderBudgets),
    [allLenderBudgets, disabledLenders]
  );

  const lendersToShow = useMemo(
    () =>
      Object.keys(allLenderBudgets).filter(
        (lenderId) => !hideDisabledLenders || enabledLenders.includes(lenderId)
      ),
    [allLenderBudgets, enabledLenders, hideDisabledLenders]
  );

  const lenderRows = useMemo(
    () =>
      lendersToShow.map((lenderId) => (
        <LenderRow
          lenderBudgets={allLenderBudgets[lenderId]}
          editable={editable}
          lenderId={lenderId}
          lenderEnabled={enabledLenders.includes(lenderId)}
          dispatch={dispatch}
          key={lenderId}
        />
      )),
    [allLenderBudgets, enabledLenders, editable, dispatch, lendersToShow]
  );

  const LenderBudgetTables = () => (
    <table>
      <thead>
        <tr>
          <th>Lender</th>
          <th>Budgets</th>
          <th>Enabled</th>
          {editable && <th>Update</th>}
        </tr>
      </thead>
      <tbody>{lenderRows}</tbody>
    </table>
  );

  const TotalBudgetTables = () => (
    <table>
      <thead>
        <tr>
          <th>Budget Type</th>
          {Object.keys(Day).map((day, index) => (
            <th key={index}>{day} Total</th>
          ))}
          <th>Monthly Total</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(enabledLenderBudgets).map(([budgetType, totals], index) => (
          <tr key={index}>
            <td>{budgetType} (enabled lenders)</td>
            {Object.keys(Day).map((day, index2) => (
              <td key={index2}>{totals[`dailyTotal${day as Day}`].toLocaleString()}</td>
            ))}
            <td>${totals.monthlyTotal.toLocaleString()}</td>
          </tr>
        ))}
        {!hideDisabledLenders &&
          Object.entries(disabledLenderBudgets).map(([budgetType, totals], index) => (
            <tr key={index}>
              <td>{budgetType} (disabled lenders)</td>
              {Object.keys(Day).map((day, index2) => (
                <td key={index2}>{totals[`dailyTotal${day as Day}`].toLocaleString()}</td>
              ))}
              <td>${totals.monthlyTotal.toLocaleString()}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <Container>
      <OwnUpSmallHeadlineBook variant="h3">Lender Budgets</OwnUpSmallHeadlineBook>
      <OwnUpCheckbox
        checked={hideDisabledLenders}
        onChange={() => setHideDisabledLenders(!hideDisabledLenders)}
      >
        Hide disabled lenders
      </OwnUpCheckbox>
      <IconLink icon={<ArrowRightIcon />} link="/lender-configuration/default">
        Edit shared rules/conditions
      </IconLink>
      <LenderBudgetTables />
      <OwnUpExtraSmallHeadlineBook variant="h5" style={{ marginTop: '24px' }}>
        Total budgets by type
      </OwnUpExtraSmallHeadlineBook>
      <TotalBudgetTables />
    </Container>
  );
};

export const Budgets = () => {
  const { userGroups } = useInternalAuth();
  const editable = !!userGroups?.some((group) => EDITOR_GROUPS.includes(group));
  const lenderBudgets = useSelector(lenderBudgetsSelector);
  const enabledLenders = useSelector(enabledLendersSelector);
  const dispatch = useDispatch();

  const props: BudgetsProps = {
    editable,
    allLenderBudgets: lenderBudgets,
    enabledLenders,
    dispatch
  };
  return <BudgetsImpl {...props} />;
};
